/*俱乐部相关
i:get || post
h:requestGet ||requestPost
*/
import axios from '../axios'
export default {
    // 通过ip获取区域信息
    ipAddressApi: () => {
      return axios.get('/api/tools/ip_address');
    },
    //俱乐部列表 
    clubListApi: (data) => {
        return axios.get('/api/tools/club/list', data);
    },
    //俱乐部详情 
    clubInfoApi: (data) => {
        return axios.get('/api/tools/club/info', data);
    },
    // 俱乐部：省市列表
    getProvinceListApi: (data) => {
        return axios.get('/api/tools/province_city', data);
    },
    // 俱乐部：增量省市列表
    getFidProvinceListApi: (data) => {
      return axios.get('/api/tools/area', data);
    },
    // 俱乐部：用户是否可以加入本俱乐部
    clubJoinAskApi: (data) => {
        return axios.post('/api/tools/club/chk/join', data);
    },
    // 供应商区：俱乐部管理
    clubManageListApi: (data) => {
        return axios.get('/api/tools/club/manager/list', data);
    },
    // 供应商区：更新俱乐部信息
    clubManageUpdateApi: (data) => {
        return axios.post('/api/tools/club/manager/update', data);
    },
    // 供应商区：俱乐部会员列表
    clubUserListApi: (data) => {
      return axios.get('/api/tools/club/manager/user/list', data);
    },
    // 供应商区：俱乐部会员导出
    clubManageUserExportApi: (data) => {
      return axios.post('/api/tools/club/manager/user/export', data);
    },
    // 供应商区：俱乐部会员详情
    clubUserInfoApi: (data) => {
      return axios.get('/api/tools/club/manager/user/info?id=' + data);
    },
    // 俱乐部活动:列表
    clubSupplierActivityListApi: (data) => {
      return axios.get('/api/tools/club/activity', data);
    },
    clubActivityListApi: (data) => {
      return axios.get('/api/tools/club/activity/list', data);
    },
    clubActivityListExportApi: (data) => {
      return axios.post('/api/tools/club/activity/export', data);
    },
    // 俱乐部活动:上下架
    clubActivityUpStatusApi: (id,data) => {
      return axios.put('api/tools/club/activity/'+ id +'/fields', data);
    },
    // 俱乐部活动:删除
    clubActivityRemoveApi: (id) => {
      return axios.iDelete('api/tools/club/activity/'+ id);
    },
    // 俱乐部活动:创建
    clubActivityCreateApi: (data) => {
      return axios.post('/api/tools/club/activity', data);
    },
    // 俱乐部活动表单:详情
    clubActivityInfoApi: (data) => {
      return axios.get('/api/tools/club/activity/'+ data +'/simple-info');
    },
    // 俱乐部活动购买:详情
    clubActivityPuchaseInfoApi: (data) => {
      return axios.get('/api/tools/club/activity/' + data);
    },
    // 俱乐部活动:更新
    clubActivityUpdateApi: (id, data) => {
      return axios.put('/api/tools/club/activity/'+ id, data);
    },
    // 俱乐部活动:活动期数
    clubActivityStageListApi: (data) => {
      return axios.get('/api/tools/club/activity/' + data + '/stages');
    },
    // 俱乐部活动:更新期数
    clubActivityStageUpdateApi: (id, data) => {
      return axios.put('/api/tools/club/activity/' + id + '/stages', data);
    },
    //俱乐部活动:类型列表
    clubActivityTypeListApi: (data) => {
      return axios.get('/api/tools/club/activity/types', data);
    },
    // 俱乐部/俱乐部活动下单
    clubCreateOrderApi: (type, productId, data) => {
      return axios.post(`/api/api/place-order/${type}/create/${productId}`, data);
    },
    // 俱乐部活动:查看数据列表
    clubActivityUserListApi: (id, data) => {
      return axios.get('/api/tools/club/activity/' + id + '/records', data);
    },
    // 俱乐部活动:查看数据-详细数据
    clubActivityUserDetailApi: (stage_id, user_uid) => {
      return axios.get(`/api/tools/club/activity/stages/${stage_id}/users/${user_uid}`);
    },
    // 俱乐部活动:查看数据列表导出
    clubActivityUserExportApi: (activity_id, data) => {
      return axios.post(`/api/tools/club/activity/${activity_id}/records/export`, data);
    },
    // 查询条件-全部期数
    clubActivityStagesAllApi: (id) => {
      return axios.get(`/api/tools/club/activity/${id}/all-stages`);
    },
    // 我的课程-俱乐部活动分类
    myCourseCategoriesApi: () => {
      return axios.get(`/api/api/center/courses/club_activity/categories`);
    },
    // 我的课程-俱乐部活动
    myCourseClubActListApi: (data) => {
      return axios.get(`/api/api/center/courses/club_activity`, data);
    }
}