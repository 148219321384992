/* 俱乐部相关 */
/* keepAlive:该页面是否缓存 */
export default [
    /**
     * 俱乐部列表
     */
    {
        path: 'club/List',
        name: 'clubList',
        meta: {
            title: '俱乐部',
            isShowTab: false,
        },
        component: () => import('@/views/club/List.vue')
    },
    {
        path: 'club/Edit',
        name: 'clubEdit',
        meta: {
            title: '编辑俱乐部',
            isShowTab: false,
        },
        component: () => import('@/views/club/Edit.vue')
    },
    {
        path: 'club/Info',
        name: 'clubInfo',
        meta: {
            title: '俱乐部详情',
            isShowTab: false,
        },
        component: () => import('@/views/club/Info.vue')
    },
    {
        path: 'club/SearchResult',
        name: 'clubSearchResult',
        meta: {
            title: '搜索结果',
            isShowTab: false,
        },
        component: () => import('@/views/club/SearchResult.vue')
    },
    {
        path: 'club/selectArea',
        name: 'selectArea',
        meta: {
            title: '地址列表',
            isShowTab: false,
        },
        component: () => import('@/views/club/selectArea.vue')
    },
    /**
     * 俱乐部活动
     */
    {
        path: 'club/activity/List',
        name: 'clubActivityList',
        meta: {
            title: '俱乐部活动',
            isShowTab: false,
        },
        component: () => import('@/views/club/activity/List.vue')
    },
    {
        path: 'club/activity/SearchResult',
        name: 'clubActivitySearchResult',
        meta: {
            title: '搜索结果',
            isShowTab: false,
        },
        component: () => import('@/views/club/activity/SearchResult.vue')
    },
    {
        path: 'club/activity/Info',
        name: 'clubActivityInfo',
        meta: {
            title: '俱乐部活动详情',
            isShowTab: false,
        },
        component: () => import('@/views/club/activity/Info.vue')
    },
    {
        path: 'club/activity/Edit',
        name: 'clubActivityEdit',
        meta: {
            title: '创建活动',
            isShowTab: false,
        },
        component: () => import('@/views/club/activity/Edit.vue')
    },
    {
        path: 'club/activity/PeriodSet',
        name: 'clubActivityPeriodSet',
        meta: {
            title: '期数管理',
            isShowTab: false,
        },
        component: () => import('@/views/club/activity/PeriodSet.vue')
    },
    {
        path: 'club/activity/PeriodHistory',
        name: 'clubActivityPeriodHistory',
        meta: {
            title: '查看往期活动',
            isShowTab: false,
        },
        component: () => import('@/views/club/activity/PeriodHistory.vue')
    },
    {
        path: 'club/activity/DataList',
        name: 'clubActivityDataList',
        meta: {
            title: '查看数据',
            isShowTab: false,
        },
        component: () => import('@/views/club/activity/DataList.vue')
    },
    {
        path: 'club/activity/UserDetail',
        name: 'clubActivityUserDetail',
        meta: {
            title: '详细数据',
            isShowTab: false,
        },
        component: () => import('@/views/club/activity/UserDetail.vue')
    },
    {
      path: 'club/activity/UserSearchResult',
      name: 'clubActivityUserSearchResult',
      meta: {
          title: '搜索',
          isShowTab: false,
      },
      component: () => import('@/views/club/activity/UserSearchResult.vue')
    },
    /**
     * 俱乐部管理
     */
    {
        path: 'club/manager/List',
        name: 'clubManagerList',
        meta: {
            title: '俱乐部管理',
            isShowTab: false,
        },
        component: () => import('@/views/club/manager/List.vue')
    },
    /**
     * 俱乐部会员
     */
    {
        path: 'club/user/List',
        name: 'clubUserList',
        meta: {
            title: '俱乐部会员',
            isShowTab: false,
        },
        component: () => import('@/views/club/user/List.vue')
    },
    {
        path: 'club/user/Detail',
        name: 'clubUserDetail',
        meta: {
            title: '详情',
            isShowTab: false,
        },
        component: () => import('@/views/club/user/Detail.vue')
    },
    {
        path: 'club/user/SearchResult',
        name: 'clubUserSearchResult',
        meta: {
            title: '搜索',
            isShowTab: false,
        },
        component: () => import('@/views/club/user/SearchResult.vue')
    },
    {
      path: 'club/PaySuccess',
      name: 'clubPaySuccess',
      meta: {
          title: '购买成功',
          isShowTab: false,
      },
      component: () => import('@/views/club/PaySuccess.vue')
    },
]