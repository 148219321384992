// 企微跳转
export default [
  {
    path: 'wecom/login',
    name: 'wecomLogin',
    meta: {
      title: '学两招・企微助手',
    },
    component: () => import('@/views/wecom/login.vue')
    },
    {
        path: 'wecom/guide',
        name: 'wecomGuide',
        meta: {
          title: '学两招・企微助手',
        },
        component: () => import('@/views/wecom/guide.vue')
    },
    {
      path: 'wecom/close',
      name: 'wecomClose',
      meta: {
        title: '学两招・企微助手',
      },
      component: () => import('@/views/wecom/close.vue')
  },
]